import type { SwiperOptions } from 'swiper/types'
import { getCurrentInstance } from 'vue'

import { useHead } from '#imports'

const useSwiperSSRStyles = (options: SwiperOptions) => {
  const $vm = getCurrentInstance()

  // @ts-ignore
  const SWIPER_WRAPPER_ID: string = $vm?.parent?.parent?.attrs?.uid || ''
  if (!SWIPER_WRAPPER_ID) return []

  const SWIPER_SSR_STYLES: Array<string> = []
  const SWIPER_WRAPPER_SELECTOR = `#c${SWIPER_WRAPPER_ID} .ui-swiper.swiper:not(.swiper-initialized) .swiper-wrapper`

  if (options.spaceBetween) {
    SWIPER_SSR_STYLES.push(`${SWIPER_WRAPPER_SELECTOR} {display: flex;}`)
    SWIPER_SSR_STYLES.push(
      `${SWIPER_WRAPPER_SELECTOR} > * {margin-right: ${options.spaceBetween}px;}`
    )
  }

  if (options.breakpoints) {
    const breakpoints = Object.entries(options.breakpoints)

    breakpoints.forEach(([breakpointValue, breakpointOptions]) => {
      if ('spaceBetween' in breakpointOptions) {
        SWIPER_SSR_STYLES.push(
          `@media (min-width: ${breakpointValue}px) {${SWIPER_WRAPPER_SELECTOR} > * {margin-right: ${breakpointOptions.spaceBetween}px;}}`
        )
      }
    })
  }

  useHead({ style: [SWIPER_SSR_STYLES.join('')] })
}

export default useSwiperSSRStyles
